
import { NewsCateActions } from "@/store/vm/news-cate/enums";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import UpdateNewsCateDetail from "@/views/news-cate/detail/Update.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "newsCate-detail",
  components: {
    UpdateNewsCateDetail,
  },
  props: {
    widgetClasses: String,
  },
  emits: ["upsert", "del"],
  data() {
    return {
      loadingModule: true,
    };
  },
  setup() {
    const store = useStore();
    const checked = ref(false);
    const { t } = useI18n();
    const route = useRoute();

    const newsCateId = route.params["newsCateId"];
    const cateName = ref("");
    let list = ref([]);
    const page = ref(1);

    const newsCateDetailIdTarget = ref<boolean | number>(false);

    const getListDetail = async () => {
      const response = await store
        .dispatch(NewsCateActions.GET_ALL_NEWS_BY_CATE_ID, {
          page: page.value,
          id: newsCateId,
        })
        .catch((e) => {
          console.log(e);
        });
      list.value = response;
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Chi tiết", ["Marketing", "Danh mục tin tức"]);
      MenuComponent.reinitialization();

      const detailCate = await store
        .dispatch(NewsCateActions.GET_DETAIL, newsCateId)
        .catch((e) => {
          console.log(e);
        });
      cateName.value = detailCate.name;

      await getListDetail();
    });

    const count = computed(() => {
      return list.value.length;
    });

    const updateNewsCateDetail = (newsCateDetailId) => {
      newsCateDetailIdTarget.value = newsCateDetailId;
    };

    const delNewsCateDetail = (newsCateDetailId) => {
      Swal.fire({
        title: "Bạn muốn xóa tin tức này khỏi danh mục?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDel = await actionDelete(newsCateDetailId);
          if (resDel["isSuccess"]) {
            await getListDetail();
          }
        }
      });
    };

    const actionDelete = async (newsCateDetailId) => {
      return store
        .dispatch(NewsCateActions.REMOVE_NEWS_FROM_CATE, newsCateDetailId)
        .catch((e) => {
          console.log(e);
        });
    };

    const modalUpdatePointId = store.state.NewsCateModule.modalUpdatePointId;

    const lang = {
      edit: t("common.edit"),
      delete: t("common.delete"),
    };

    const onUpdateSuccess = async () => {
      await getListDetail();
    };

    return {
      list,
      cateName,
      checked,
      count,
      getListDetail,
      updateNewsCateDetail,
      onUpdateSuccess,
      delNewsCateDetail,
      modalUpdatePointId,
      newsCateDetailIdTarget,
      lang,
      moment,
      page,
    };
  },
});
