
import { computed, defineComponent, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { StepResult } from "@/store/captain/CaptainModule";
import { NewsCateActions, NewsCateGetters } from "@/store/vm/news-cate/enums";

export default defineComponent({
  name: "update-news-cate-detail",
  components: {},
  props: {
    newsCateDetailId: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  emits: ["updateSuccess"],
  setup(props, { emit }) {
    const store = useStore();

    const formRef = ref<null | HTMLFormElement>(null);
    const newsDetailUpdatePointRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const defaultData = {
      id: 0,
      point: 10,
      title: "",
    };

    const formData = ref({ ...defaultData });

    const modalUpdatePointId = computed(() => {
      return store.state.NewsCateModule.modalUpdatePointId;
    });

    const rules = ref({
      point: [
        {
          required: true,
          message: "Yêu cầu điểm hiển thị của tin tức",
          trigger: "change",
        },
      ],
    });

    const newsCateDetailIdTarget = computed(() => {
      console.log(`get detail target id ${props.newsCateDetailId}`);
      return props.newsCateDetailId;
    });

    watch(newsCateDetailIdTarget, (currentValue) => {
      if (currentValue) {
        // update
        const detail =
          store.getters[NewsCateGetters.GET_NEWS_CATE_DETAIL_BY_ID](
            currentValue
          );
        console.log(detail);
        formData.value = detail;
      } else {
        reset();
      }
    });

    const reset = () => {
      formData.value = defaultData;
    };

    const actionUpdate = async (par) => {
      return await store
        .dispatch(NewsCateActions.UPDATE_DETAIL_POINT, par)
        .catch((e) => {
          console.log(e);
        });
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          let stepResult: StepResult;
          stepResult = await actionUpdate(formData.value);

          loading.value = false;
          if (stepResult.isSuccess) {
            hideModal(newsDetailUpdatePointRef.value);
            Swal.fire({
              position: "top-end",
              text: "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
            emit("updateSuccess");
            reset();
          } else {
            Swal.fire({
              position: "top-end",
              text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    return {
      newsDetailUpdatePointRef,
      modalUpdatePointId,
      defaultData,
      formData,
      submit,
      formRef,
      rules,
      loading,
      newsCateDetailIdTarget,
    };
  },
});
